<template>
  <div class="admin-add-edit-modal">
    <a-modal :visible="visible" :title="title" centered>
      <a-form-model
        ref="form"
        :model="form"
        :rules="rules"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
      >
        <!-- 用户名 -->
        <a-form-model-item label="用户名" prop="username">
          <a-input v-model="form.username" placeholder="请输入" allowClear />
        </a-form-model-item>
        <!-- 密码 -->
        <a-form-model-item
          v-if="type === 'edit'"
          label="密码"
          prop="password"
          :extra="type === 'add' ? '默认密码: pinews2023' : ''"
        >
          <a-input
            v-model="form.password"
            placeholder="请输入"
            allowClear
            type="password"
          />
        </a-form-model-item>
        <!-- 邮箱 -->
        <a-form-model-item
          label="Email"
          prop="email"
          :extra="type === 'add' ? '默认密码: pinews2023' : ''"
        >
          <a-input v-model="form.email" placeholder="请输入" allowClear />
        </a-form-model-item>
        <!-- 权限 -->
        <a-form-model-item label="权限" prop="permission">
          <a-select v-model="form.permission" placeholder="请选择权限">
            <a-select-option
              v-for="item in permissionOptions"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <a-button key="back" @click="onClose"> 取消 </a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="onOk">
          提交
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
// 系统管理员新增/编辑弹窗
import md5 from 'md5';
import { PERMISSION } from '@/config/const.js';

export default {
  name: 'AdminAddEditModal',
  data() {
    return {
      visible: false,
      title: '新增系统管理员',
      type: 'add',
      loading: false,
      form: {
        _id: undefined,
        username: undefined,
        password: undefined,
        email: undefined,
        permission: undefined,
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          {
            min: 3,
            max: 20,
            message: '长度在 3 到 20 个字符',
            trigger: 'blur',
          },
        ],
        email: [
          { required: true, message: '请输入Email', trigger: 'blur' },
          { type: 'email', message: '请输入正确的Email', trigger: 'blur' },
        ],
        permission: [
          { required: true, message: '请选择权限', trigger: 'blur' },
        ],
      },
    };
  },
  computed: {
    // 权限
    permissionOptions() {
      return Object.values(PERMISSION);
    },
  },
  methods: {
    // 打开弹窗
    open({ title = '新增系统管理员', record = null }) {
      this.title = title;
      this.visible = true;
      this.$nextTick(() => {
        if (record) {
          this.type = 'edit';
          const { _id, username, email, permission } = record;
          this.form = {
            _id,
            username,
            email,
            permission,
          };
        } else {
          this.type = 'add';
          this.$refs.form.resetFields();
        }
      });
    },
    // 关闭弹窗
    onClose() {
      this.visible = false;
    },
    // 确定
    onOk() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          if (this.type === 'add') {
            await this.add();
          } else {
            await this.edit();
          }
          this.loading = false;
        }
      });
    },
    // 新增
    async add() {
      const { err, res } = await this.$api.admin.add(this.form);
      if (!err) {
        this.$message.success(res.msg);
        this.onClose();
        this.$refs.form.resetFields();
        this.$emit('done');
      } else {
        this.$message.error(res.msg);
      }
      this.loading = false;
    },
    // 编辑
    async edit() {
      const data = { ...this.form };
      if (this.form.password) {
        data.password = md5(this.form.password);
      }
      const { err, res } = await this.$api.admin.edit(data);
      if (!err) {
        this.$message.success(res.msg);
        this.onClose();
        this.$refs.form.resetFields();
        this.$emit('done');
      } else {
        this.$message.error(res.msg);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="less" scoped>
.admin-add-edit-modal {
}
</style>
