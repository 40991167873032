<template>
  <div class="admin-list">
    <a-row type="flex" justify="space-between">
      <a-col>
        <a-form-model layout="inline">
          <a-form-model-item label="用户名:">
            <a-input
              v-model="form.username"
              placeholder="username"
              allowClear
            />
          </a-form-model-item>
          <a-form-model-item label="Email:">
            <a-input v-model="form.email" placeholder="email" allowClear />
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" @click="onSearchClick">搜索</a-button>
          </a-form-model-item>
        </a-form-model>
      </a-col>
      <a-col>
        <a-button type="primary" @click="onAddClick">新增</a-button>
      </a-col>
    </a-row>
    <!-- 表格 -->
    <a-table
      class="list"
      :columns="columns"
      :dataSource="data"
      :loading="loading"
      :pagination="pagination"
      :rowKey="(record) => record._id"
    >
      <!-- 序号 -->
      <template slot="index" slot-scope="text, record, index">
        {{ index + 1 }}
      </template>
      <!-- 状态 -->
      <template slot="available" slot-scope="text, record">
        <a-tag :color="record.available ? 'green' : 'red'">
          {{ record.available ? '正常' : '禁用' }}
        </a-tag>
      </template>
      <!-- 操作 -->
      <template slot="action" slot-scope="text, record">
        <a-space>
          <a-button type="link" @click="onEditClick(record)">编辑</a-button>
          <a-popconfirm
            v-if="record.available"
            title="确定禁用该账号？"
            ok-text="Yes"
            cancel-text="No"
            @confirm="onDisableClick(record)"
          >
            <a-button type="link" style="color: #f30"> 禁用 </a-button>
          </a-popconfirm>
          <a-popconfirm
            v-else
            title="确定启用该账号？"
            ok-text="Yes"
            cancel-text="No"
            @confirm="onEnableClick(record)"
          >
            <a-button type="link"> 启用 </a-button>
          </a-popconfirm>
        </a-space>
      </template>
    </a-table>
    <!-- 新增编辑弹窗 -->
    <AdminAddEditModal ref="adminAddEditModal" @done="onSuccess" />
  </div>
</template>

<script>
import AdminAddEditModal from '@/components/AdminAddEditModal.vue';
import { PERMISSION } from '@/config/const.js'

// 系统管理员
export default {
  name: 'AdminList',
  components: {
    AdminAddEditModal,
  },
  data() {
    return {
      loading: false,
      form: {
        username: undefined,
        email: undefined,
      },
      pagination: {
        current: 0,
        pageSize: 10,
        total: 0,
      },
      columns: [
        {
          title: '序号',
          key: 'index',
          scopedSlots: { customRender: 'index' },
          width: 80,
        },
        { title: '用户名', dataIndex: 'username' },
        { title: 'Email', dataIndex: 'email' },
        {
          title: '权限',
          dataIndex: 'permission',
          customRender: (text) => {
            const _premission = Object.values(PERMISSION).find((item) => item.value === text);
            return _premission ? _premission.label : '-';
          },
        },
        {
          title: '创建时间',
          dataIndex: 'create_at',
          customRender: (text) =>
            this.$dayjs(text).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
          title: '最后登录时间',
          dataIndex: 'login_at',
          customRender: (text) =>
            text ? this.$dayjs(text).format('YYYY-MM-DD HH:mm:ss') : '-',
        },
        {
          title: '更新时间',
          dataIndex: 'update_at',
          customRender: (text) =>
            text ? this.$dayjs(text).format('YYYY-MM-DD HH:mm:ss') : '-',
        },
        {
          title: '状态',
          dataIndex: 'available',
          scopedSlots: { customRender: 'available' },
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          align: 'center',
        },
      ],
      data: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 获取列表
    async getList() {
      this.loading = true;
      const { current, pageSize } = this.pagination;
      const { username, email } = this.form;
      const { err, res } = await this.$api.admin.list({
        pageIndex: current,
        pageSize,
        username,
        email,
      });
      if (!err) {
        this.data = res.result.list;
        this.pagination.total = res.result.total;
      }
      this.loading = false;
    },
    // 点击搜索
    onSearchClick() {
      this.getList();
    },
    // 点击添加
    onAddClick() {
      this.$refs.adminAddEditModal.open({ title: '新增系统管理员' });
    },
    // 添加成功
    onSuccess() {
      this.getList();
    },
    // 点击禁用
    async onDisableClick(record) {
      const { err, res } = await this.$api.admin.disable({ _id: record._id });
      if (!err) {
        this.$message.success(res.msg);
        this.getList();
      }
    },
    // 点击取消禁用
    async onEnableClick(record) {
      const { err, res } = await this.$api.admin.enable({ _id: record._id });
      if (!err) {
        this.$message.success(res.msg);
        this.getList();
      }
    },
    // 点击编辑
    onEditClick(record) {
      this.$refs.adminAddEditModal.open({
        title: '编辑系统管理员',
        record,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.admin-list {
  .list {
    margin-top: 16px;
  }
}
</style>
